.sad {
    background-color: rgba(1, 132, 255)
}

.happy {
    background-color: rgba(255, 186, 1)
}

.angry {
    background-color: rgba(255, 1, 1)
}

.text-editor{
    min-height: 50vh !important;
}
.fear {
    background-color: rgba(1, 255, 79)
}

.surprise {
    background-color: rgba(135, 1, 255)
}

.circle-viz {
    display: flex;
    flex-direction: column;
    min-height: 150px;
    padding: 1rem;
}

.circle-container{
    display: flex;
}


.circle {
    margin: auto;
    transition: 3s;
}

.viz{
    height: 100%;
    width: 100%;
    transition: 10s;
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
    min-height: 100vh;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.journal{
    background-color: white;
    height: 100%;
    width: 100%;
    padding: 2rem;
    border-radius: 1%;
    border: 1px solid #979797;
    min-height: 75vh;
}

.journal-date{
    width: 100%;
}

.circle-text{
    font-weight: 600;
    width: 75px;
}
.emotion-accordion{
    width: 100%;
    margin-top: 3rem;
}